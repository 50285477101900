import "antd/dist/antd.css";
import "./App.css";
import {
  Heading as EvergreenHeading,
  Avatar as EvergreenAvatar,
  Text,
  Badge as EvergreenBadge,
} from "evergreen-ui";
import { SocialIcon as ReactSocialIcon } from "react-social-icons";
import styled from "styled-components";
import meImg from "./images/me.jpg";
import { Divider as AntDivider } from "antd";
import { Experience, experiences } from "./Experience";
import { SkillsWordcloud } from "./SkillsWordcloud";
import { differenceInYears } from "date-fns/esm";

export const Page = styled.div`
  padding-bottom: 60px;
`;
export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Profile = styled.div`
  padding: 80px 0px 0px 0px;
`;
export const ProfileHeading = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const Avatar = styled(EvergreenAvatar)`
  width: 301px;
  height: 301px;
  border: double 2px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(
      #ff0000,
      #ffff00,
      #00ff00,
      #00ffff,
      #0000ff,
      #ff00ff,
      #ff0000
    );
  background-image: linear-gradient(white, white),
    conic-gradient(
      #ff0000,
      #ffff00,
      #00ff00,
      #00ffff,
      #0000ff,
      #ff00ff,
      #ff0000
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;
`;
export const HeadingText = styled(EvergreenHeading)`
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
`;
export const SecondaryHeadingText = styled(EvergreenHeading)`
  font-size: 26px;
  line-height: 36px;
  text-align: center;
`;

export const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const Content = styled.div`
  margin-bottom: 20px;
`;
export const BoldText = styled(Text)`
  font-weight: bold;
`;
export const Badge = styled(EvergreenBadge)``;
export const Socials = styled.div``;
export const SocialIconCustom = styled(ReactSocialIcon)`
  margin: 0px 4px;
`;
export const DividerContainer = styled.div`
  padding: 0px 30px;
  width: 100%;
  max-width: 400px;
`;
export const Divider = styled(AntDivider)`
  padding: 10px 0px 10px 0px;
  color: #00000073 !important;
`;

export const SocialIcon = (props: { url: string; network: string }) => {
  const size = "30px";
  return <SocialIconCustom {...props} style={{ width: size, height: size }} />;
};

function App() {
  const startedWorking = new Date("07/01/2013");
  const currentTime = new Date();
  const yearsExperience = differenceInYears(currentTime, startedWorking);
  const jobTitle = `${experiences[0].jobTitle} @ ${experiences[0].company}`;

  document.title = `Joey Hotz - ${jobTitle}`;

  return (
    <Page>
      <Center>
        <Profile>
          <ProfileHeading>
            <Avatar src={meImg} />
          </ProfileHeading>
          <ProfileContent>
            <Header>
              <HeadingText>Joey Hotz</HeadingText>
              <SecondaryHeadingText>{jobTitle}</SecondaryHeadingText>
            </Header>
            <Content>
              <Text>
                <b>{yearsExperience}</b> years experience
              </Text>
              <BoldText> · </BoldText>
              <Badge color="green">Front-end</Badge>
              <BoldText> · </BoldText>
              <Badge color="green">Back-end</Badge>
              <BoldText> · </BoldText>
              <Badge color="green">Infra</Badge>
            </Content>
            <Socials>
              <SocialIcon
                url={"https://github.com/joeyhotz"}
                network={"github"}
              />
              <SocialIcon
                url={"https://www.linkedin.com/in/joey-hotz-51936848"}
                network={"linkedin"}
              />
              <SocialIcon
                url={"mailto:joeyhotz1@gmail.com"}
                network={"email"}
              />
            </Socials>
          </ProfileContent>
        </Profile>
        <DividerContainer>
          <Divider plain>Experience</Divider>
        </DividerContainer>
        <Experience />
        <DividerContainer>
          <Divider plain>Skills</Divider>
        </DividerContainer>
        <SkillsWordcloud />
      </Center>
    </Page>
  );
}

export default App;
