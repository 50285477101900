import { Datum, WordCloud } from "@ant-design/charts";
import styled from "styled-components";
import uniqolor from "uniqolor";

export const WordCloudContainer = styled.div`
  max-width: 700px;
  width: 100%;
`;

export const wordcloudTooltipFormatter = (datum: Datum) => {
  return { name: datum.text, value: datum.value };
};

export const SkillsWordcloud = () => {
  const wordField = "name";
  const weightField = "weight";
  const data = [
    { name: "Node.js", weight: 5 },
    { name: "TypeScript", weight: 5 },
    { name: "React", weight: 5 },
    { name: "JavaScript", weight: 5 },
    { name: "AWS", weight: 5 },
    { name: "BFF", weight: 3 },
    { name: "Continuous Integration", weight: 4 },
    { name: "Continuous Delivery", weight: 4 },
    { name: "Pipelines", weight: 4 },
    { name: "Agile", weight: 4 },
    { name: "CSS", weight: 5 },
    { name: "DynamoDB", weight: 3 },
    { name: "Event Driven Architecture", weight: 3 },
    { name: "CSS in JS", weight: 4 },
    { name: "HTML", weight: 3 },
    { name: "D3.js", weight: 3 },
    { name: "Lambda", weight: 4 },
    { name: "Serverless", weight: 4 },
    { name: "Microservices", weight: 3 },
    { name: "SQL", weight: 5 },
    { name: "Postgres", weight: 4 },
    { name: "Bash", weight: 3 },
    { name: "Terraform", weight: 4 },
    { name: "Test Driven Development", weight: 3 },
    { name: "C#", weight: 4 },
    { name: ".NET", weight: 4 },
    { name: "Docker", weight: 4 },
    { name: "Java", weight: 3 },
    { name: "Python", weight: 3 },
    { name: "Webpack", weight: 4 },
    { name: "SCSS", weight: 4 },
  ];
  return (
    <WordCloudContainer>
      <WordCloud
        data={data}
        wordField={wordField}
        weightField={weightField}
        color={() => uniqolor.random().color}
        tooltip={{ showContent: false }}
        autoFit={true}
        wordStyle={{
          fontFamily: `-apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
          padding: 6,
        }}
      />
      <div style={{ display: "none" }}>
        <h2>Skills</h2>
        <ul>
          {data.map((datum, i) => (
            <li key={i}>{datum.name}</li>
          ))}
        </ul>
      </div>
    </WordCloudContainer>
  );
};
