import { Timeline as AntTimeline, Typography } from "antd";
import styled from "styled-components";
import { differenceInMonths, differenceInYears, format } from "date-fns";

export const TimelineContainer = styled.div`
  max-width: 800px;
  display: flex;
  width: 90%;
`;
export const Timeline = styled(AntTimeline)`
  flex: 1;
`;

interface ExperienceData {
  time: { start: Date; end: Date | null };
  company: string;
  jobTitle: string;
  color: string;
}

const generateTimeLabel = (start: Date, end: Date | null, bold: boolean) => {
  const years = differenceInYears(end || new Date(), start);
  const months = differenceInMonths(end || new Date(), start) - years * 12;

  const actualMonths = months > 1 ? months : months + 1;

  const yearsLabel = `${years} year${years > 1 ? "s" : ""} and `;
  const monthsLabel = `${actualMonths} month${actualMonths > 1 ? "s" : ""}`;

  const diffLabel = `${years > 0 ? yearsLabel : ""}${
    actualMonths > 0 ? monthsLabel : ""
  }`;

  const dateFormat = "MMMM ''yy'";

  return (
    <>
      <span style={{ fontWeight: bold ? "bold" : "normal" }}>{`${format(
        start,
        dateFormat
      )} – ${end ? format(end, dateFormat) : "Present"}`}</span>
      <>
        <br />
        <Typography.Text style={{ color: "rgba(0, 0, 0, 0.25)" }}>
          {diffLabel}
        </Typography.Text>
      </>
    </>
  );
};

export const experiences: ExperienceData[] = [
  {
    company: "Eucalyptus",
    jobTitle: "Software Developer",
    time: { start: new Date("10/12/2021"), end: null },
    color: "purple",
  },
  {
    company: "Thoughtworks",
    jobTitle: "Senior Software Developer Consultant",
    time: { start: new Date("05/12/2021"), end: new Date("10/11/2021") },
    color: "green",
  },
  {
    company: "Thoughtworks",
    jobTitle: "Software Developer Consultant",
    time: { start: new Date("07/01/2018"), end: new Date("05/11/2021") },
    color: "green",
  },
  {
    company: "American Express",
    jobTitle: "Business Analyst",
    time: { start: new Date("01/01/2017"), end: new Date("07/01/2017") },
    color: "blue",
  },
  {
    company: "Appliances Online",
    jobTitle: "Software Developer",
    time: { start: new Date("07/01/2015"), end: new Date("01/01/2017") },
    color: "red",
  },
  {
    company: "Blue Island Press",
    jobTitle: "Developer",
    time: { start: new Date("07/01/2013"), end: new Date("01/01/2019") },
    color: "gold",
  },
];

export const Experience = () => {
  const activeExperienceBold = true;

  return (
    <TimelineContainer>
      <Timeline mode="left">
        {experiences.map((experience, i) => (
          <Timeline.Item
            key={i}
            color={experience.color}
            label={generateTimeLabel(
              experience.time.start,
              experience.time.end,
              activeExperienceBold && i === 0
            )}
            style={{ paddingBottom: "30px" }}
          >
            <div>
              {activeExperienceBold && i === 0 ? (
                <b>{experience.company}</b>
              ) : (
                experience.company
              )}
            </div>
            <div>
              <Typography.Text style={{ color: "rgba(0, 0, 0, 0.25)" }}>
                {experience.jobTitle}
              </Typography.Text>
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </TimelineContainer>
  );
};
